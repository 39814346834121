import { Outlet, useRouteLoaderData } from '@remix-run/react';
import { $routeId } from 'remix-routes';
import { type ExternalScriptsHandle } from 'remix-utils/external-scripts';

import { EnumsPageName } from '@lp-lib/api-service-client/public';

import { hubspotScripts } from '../../src/tracking/hubspot';
import { AnonProvider } from '../components/AnonLayout';
import { fetchMostPopularProduct } from '../fetches/fetchMostPopularProduct';
import { fetchPage } from '../fetches/fetchPage';

export const handle: ExternalScriptsHandle = {
  scripts: () => {
    return [...hubspotScripts()];
  },
};

export async function loader() {
  const pageName = EnumsPageName.PageNameExplore;
  const page = await fetchPage(pageName);
  const product = await fetchMostPopularProduct();
  return { pageName, page, product: product || null };
}

export function useExploreData() {
  const data = useRouteLoaderData<typeof loader>($routeId('routes/explore'));
  if (!data) throw new Error('Explore data not found');
  return data;
}

export function shouldRevalidate() {
  return false;
}

export default function Component() {
  return (
    <AnonProvider>
      <Outlet />
    </AnonProvider>
  );
}
